import { getResourceUrl } from '@/utils';
// import { ModalDialog, PopUp, ButtonIcon, Icon } from 'components'
import { Upload } from 'components';
import { ERROR, INFO } from 'constants';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { confirm, getGId, getLimitSubStr, getStrWidth, getUuid, showInfo, typeIs } from 'utils';
import { uploadAttachmentClass } from './index.scss';

const downLoadUrl = 'api/Basic/File/getFile';
const getOrigin = () => {
  const { origin } = window.location;
  const isDevelopment = process.env.NODE_ENV === 'development';
  return isDevelopment ? '' : origin;
};

export default class UploadAttach extends PureComponent {
  static propTypes = {
    labelText: PropTypes.string, // 组件前面的label的文字
    maxNum: PropTypes.number, // 支持的最大上传附件的个数
    maxSize: PropTypes.number, // 支持单个附件最大不超过x兆(M)
    iconType: PropTypes.string, // “添加附件”前面的图标
    action: PropTypes.string, // 值包括: "uploadimage", "uploadfile"
    type: PropTypes.string,
    disable: PropTypes.bool,
    data: PropTypes.array,
    ignorePmsn: PropTypes.bool,
    isOss: PropTypes.bool, // 是否需要oss上传
    showTipTxt: PropTypes.bool,
    onChange: PropTypes.func,
    fileTypeEnum: PropTypes.array,
    uploadChildren: PropTypes.any,
  };
  static defaultProps = {
    maxNum: 3,
    disable: false,
    maxSize: 2,
    ignorePmsn: false,
    labelText: '上传附件',
    iconType: 'icon-upload',
    action: 'uploadfile',
    showTipTxt: true,
    isOss: false,
    fileTypeEnum: ['.doc', '.docx', '.xls', '.xlsx', '.pdf', '.zip', '.rar'],
  };
  constructor(props) {
    super(props);
    this.state = {
      files: this.getDefData(props) || [],
      loading: false,
    };
  }
  // 下面的componentDidMount和componentDidUpdate函数都是给超链接添加download属性。
  componentDidMount = () => {
    const { files } = this.state;
    const linkDom = document.getElementsByClassName('ant-upload-list-item-name');
    if (files.length !== linkDom.length) return null;
    for (let i = 0; i < linkDom.length; i++) {
      if (!linkDom[i].getAttribute('download')) {
        linkDom[i].setAttribute('download', files[i].original);
      }
    }
  };

  componentDidUpdate = () => {
    const { files } = this.state;
    const linkDom = document.getElementsByClassName('ant-upload-list-item-name');
    if (files.length !== linkDom.length) return null;
    for (let i = 0; i < linkDom.length; i++) {
      if (!linkDom[i].getAttribute('download')) {
        linkDom[i].setAttribute('download', files[i].original);
      }
    }
  };

  getDefData = props => {
    const { data } = props;
    const { type } = props;
    if (!typeIs(data, 'array')) return null;
    const files = data.map(item => {
      const param = {
        gid: getGId(),
        type,
        filename: item.title,
        showname: encodeURIComponent(item.original),
      };
      const nameArr = item.original.split('.');
      const nameType = `.${nameArr[nameArr.length - 1]}`;
      const req = JSON.stringify(param);
      const otherParam = `req=${req}`;
      const origin = getOrigin();
      let name;
      if (getStrWidth(item.original) > 160) {
        const subStr = getLimitSubStr(item.original, 160 - getStrWidth(`...${nameType}`));
        name = `${subStr}...${nameType}`;
      } else {
        name = item.original;
      }
      const originUrl = `${origin}/${downLoadUrl}?${otherParam}`;
      const url = getResourceUrl({
        originUrl,
        fileContent: item,
      });
      return {
        ...item,
        uid: getUuid(),
        name,
        status: 'done',
        url,
      };
    });
    return files;
  };

  getData = () => {
    const fileList = this.state.files;
    if (!typeIs(fileList, 'array')) return null;
    const files = fileList.map(item => ({
      title: item.title,
      original: item.original,
      size: item.size,
    }));
    return files;
  };

  handleChange = () => {
    const { oriFileBlob } = this.state;
    const originFiles = this.state.files;
    const files = this.getData();
    const params = { data: files, originData: originFiles, oriFileBlob };
    this.props.onChange && this.props.onChange(params);
  };

  beforeUpload = file => {
    const { fileTypeEnum } = this.props;
    const { size } = file;
    const nameArr = file.name.split('.');
    const type = `.${nameArr[nameArr.length - 1]}`;
    const { maxSize } = this.props;
    // 后端发送请求的时候会有一点点误差，前端少校验1K。
    const toBig = size >= maxSize * 1024 * 1024 - 1024;
    if (!fileTypeEnum.includes(type.toLowerCase())) {
      showInfo(ERROR, '不支持该文件格式！');
      return false;
    } else if (toBig) {
      showInfo(ERROR, `文件大小限制：小于${maxSize}M！`);
      return false;
    }
    this.setState({
      loading: true,
    });
    return file;
  };

  doLoading = status => {
    this.setState({
      loading: status,
    });
  };

  getLoading = () => {
    return this.state.loading;
  };

  onSuccess = (res, oriFileBlob) => {
    this.setState({
      loading: false,
    });
    if (+res.errno !== 0) {
      res.errmsg && showInfo(ERROR, res.errmsg);
      return;
    }
    const file = res.res;
    const { files } = this.state;
    const { type } = this.props;
    const nameArr = file.original.split('.');
    const nameType = `.${nameArr[nameArr.length - 1]}`;
    const param = {
      gid: getGId(),
      type,
      filename: file.title,
      showname: encodeURIComponent(file.original),
    };
    const req = JSON.stringify(param);
    const otherParam = `req=${req}`;
    const origin = getOrigin();
    let name;
    if (getStrWidth(file.original) > 160) {
      const subStr = getLimitSubStr(file.original, 160 - getStrWidth(`...${nameType}`));
      name = `${subStr}...${nameType}`;
    } else {
      name = file.original;
    }
    const originUrl = `${origin}/${downLoadUrl}?${otherParam}`;
    const url = getResourceUrl({
      originUrl,
      fileContent: file,
    });
    const item = {
      ...file,
      url,
      uid: getUuid(),
      name,
      status: 'done',
    };
    const rtnParam = { oriFileBlob };
    rtnParam.files = [...files];
    rtnParam.files.push(item);
    this.setState(rtnParam);
    this.handleChange();
  };

  onRemove = async index => {
    const { files } = this.state;
    const delFiles = [...files];
    const confirmContent = '您确定要删除附件吗？';
    if (await confirm(INFO, confirmContent, {}, '操作确认')) {
      delFiles.splice(index, 1);
    }
    this.setState({ files: delFiles, oriFileBlob: null });
    this.handleChange();
  };

  content = () => {
    const { iconType, action, type, disable, uploadChildren } = this.props;
    const { files } = this.state;
    const fileNum = files.length;
    const t = new Date().valueOf();
    const actionUrl = `/Basic/File/uploadFile?t=${t}`;
    const req = { action, type };
    return (
      <Upload
        className="attachment-content"
        action={actionUrl}
        onSuccess={this.onSuccess}
        isOss={this.props.isOss}
        showUploadList
        data={req}
        name="upfile"
        disabled={disable}
        onRemove={index => this.onRemove(index)}
        beforeUpload={this.beforeUpload}
        doLoading={this.doLoading}
        fileList={files}
        defaultFileList={files}
        type={type}
      >
        {!disable &&
          fileNum < this.props.maxNum &&
          (uploadChildren || (
            <a>
              <i className={`fn-icon fn-${iconType}`} />
              添加附件
            </a>
          ))}
      </Upload>
    );
  };

  render() {
    const { maxSize, maxNum, ignorePmsn, showTipTxt } = this.props;
    const { loading } = this.state;

    if (!ignorePmsn && !window.permissionObj.balance_upload_file) {
      return null;
    }

    return (
      <div className={uploadAttachmentClass}>
        <div className="text">
          <label className="fn-label">{this.props.labelText}</label>
          {showTipTxt && (
            <label className="label-remark">
              *最多上传{maxNum}个文件，每个文件不得超过{maxSize}M
            </label>
          )}
          {loading && <span className="loading-mark">[上传中...]</span>}
        </div>
        {this.content()}
      </div>
    );
  }
}
