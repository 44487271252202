import { CAN_PREVIEW_FILE_TYPE } from '@/constants/fileType.js';
import extraGlobalState from '@/utils/extraGlobalState';
import { getFileType } from '@/utils/getFileType';
import { getNtoccOssKey } from '@/utils/oss';
import { uploadFileToOss } from '@gui/utils/es/oss-utils';
import { Icon } from 'components';
import { ERROR } from 'constants';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compressImg, fetchApi, fetchJAVA, showInfo, viewImage } from 'utils';
import { prefixCls } from './index.scss';

class Upload extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    // 上传的地址
    action: PropTypes.string,
    onSuccess: PropTypes.func,
    // 是否展示文件列表
    showUploadList: PropTypes.bool,
    isOss: PropTypes.bool,
    // 上传所需参数或返回上传参数的方法
    data: PropTypes.func,
    // 发到后台的文件参数名
    name: PropTypes.string,
    // 点击移除文件时的回调，返回值为 false 时不移除
    onRemove: PropTypes.func,
    // 上传文件之前的钩子，参数为上传的文件，若返回 false 则停止上传
    beforeUpload: PropTypes.func,
    doLoading: PropTypes.func, // 处理loading状态
    // 已经上传的文件列表（受控）
    fileList: PropTypes.array,
    // 如果有默认的文件列表，则组件不再受控
    defaultFileList: PropTypes.array,
    accept: PropTypes.array,
    disabled: PropTypes.bool,
    // 上传的文件类型
    fileType: PropTypes.oneOf(['image']),
    // fileType为image时，图片压缩的质量参数
    quality: PropTypes.number,
    type: PropTypes.string,
  };
  static defaultProps = {
    action: '/Basic/File/uploadFile?',
    beforeUpload: () => true,
    name: 'file',
    isOss: false,
    data: file => ({ action: 'uploadfile', type: 'message' }),
    quality: 0.8,
    type: 'balance',
  };
  constructor(props) {
    super(props);
    this.underControl = !!props.fileList;
    if (!this.underControl) {
      this.state = {
        fileList: props.defaultFileList || [],
      };
    }
  }
  onClick = () => {
    if (!this.props.disabled) {
      this.fileInput.click();
    }
  };

  handleSelect = async e => {
    if (!e || !e.target.files.length) return;
    const file = e.target.files[0];
    const theFile = {
      name: file.name,
      size: file.size,
      uid: `${new Date().getTime()}-${file.name}`,
      url: e.target.value,
    };
    const beforeCheck = this.props.beforeUpload(file);
    const { fileType, quality } = this.props;
    if (beforeCheck) {
      if (this.props.isOss) {
        this.uploadByOss(file, beforeCheck, theFile);
      } else {
        const formData = new FormData();
        const { name, data } = this.props;
        const req = typeof data === 'function' ? data(file) : data;

        formData.append('req', JSON.stringify(req));
        if (fileType === 'image') {
          const rst = await compressImg(file, { quality });
          formData.append(name, rst?.file);
        } else {
          formData.append(name, file);
        }

        fetchApi(this.props.action, {
          method: 'POST',
          credentials: 'include',
          body: formData,
        }).then(res => {
          this.props.onSuccess && this.props.onSuccess(res, file);
          if (!this.underControl) {
            const { fileList } = this.state;
            fileList.push(theFile);
            this.setState({
              fileList: [...fileList],
            });
          }
        });
      }
    }
  };
  uploadCYTOSS = async (file, theFile) => {
    try {
      const fileRes = await uploadFileToOss({
        file,
        request: fetchConfig => {
          const { params } = fetchConfig;
          return fetchJAVA('/cmm-basic/resource/getAuthKey', {
            method: 'POST',
            ...params,
          });
        },
        requestConfig: {
          params: {
            body: {
              req: {
                type: this.props.type,
                group_id: window?.group_id,
              },
            },
          },
        },
        formateReceiveData: resData => {
          const { path, uploadData } = resData;
          const fileName = path.split('/').pop();
          return {
            name: fileName,
            path,
            showName: uploadData.name,
          };
        },
      });
      const res = {
        errno: 0,
        res: {
          original: file.name,
          size: file.size,
          title: fileRes.path,
        },
      };
      this.props.onSuccess?.(res, file);
      if (!this.underControl) {
        const { fileList } = this.state;
        fileList.push(theFile);
        this.setState({
          fileList: [...fileList],
        });
      }
    } catch (error) {
      //
    }
  };
  uploadOldOss = async (file, beforeCheck, theFile) => {
    const _this = this;
    const ossConfig = await getNtoccOssKey(true, this.props.type);
    if (!ossConfig) {
      this.props.doLoading && this.props.doLoading(false);
      showInfo(ERROR, '上传图片失败，请稍后重试或联系售后支持！');
      return;
    }

    const fileName = `${beforeCheck.uniqueName || beforeCheck.name}`;
    const time = Date.now();
    const nameArr = fileName.split('.');
    const type = `.${nameArr[nameArr.length - 1]}`;
    const ossKey = `${ossConfig.dir}${time}${type}`;

    const reqObj = {
      key: ossKey,
      policy: ossConfig.policy,
      OSSAccessKeyId: ossConfig.accessId,
      signature: ossConfig.signature,
      success_action_status: '200',
      name: fileName,
    };

    const formData = new FormData();
    Object.keys(reqObj).forEach(ikey => formData.append(ikey, reqObj[ikey]));
    formData.append('file', file, fileName);

    const xhr = new XMLHttpRequest();

    // eslint-disable-next-line no-shadow
    xhr.onerror = function error(e) {
      _this.props.doLoading && _this.props.doLoading(false);
      showInfo(ERROR, '上传图片失败，请稍后重试或联系售后支持！');
    };
    xhr.onload = function onload() {
      if (xhr.status < 200 || xhr.status >= 300) {
        _this.props.doLoading && _this.props.doLoading(false);
        showInfo(ERROR, '上传图片失败，请稍后重试或联系售后支持！');
        return;
      }
      const res = {
        errno: 0,
        res: {
          original: file.name,
          size: file.size,
          title: ossKey,
        },
      };
      _this.props.onSuccess && _this.props.onSuccess(res, file);
      if (!_this.underControl) {
        const { fileList } = _this.state;
        fileList.push(theFile);
        _this.setState({
          fileList: [...fileList],
        });
      }
    };

    xhr.open('post', ossConfig.host, true);
    xhr.send(formData);
  };
  uploadByOss = (file, beforeCheck, theFile) => {
    const useNewWayEnabled = extraGlobalState.getState('isNewWayEnabled');
    if (useNewWayEnabled) {
      return this.uploadCYTOSS(file, theFile);
    } else {
      return this.uploadOldOss(file, beforeCheck, theFile);
    }
  };
  handleRemove = index => e => {
    e.stopPropagation();
    e.preventDefault();
    this.fileInput.value = '';
    if (this.underControl) {
      this.props.onRemove && this.props.onRemove(index);
    } else {
      const { fileList } = this.state;
      fileList.splice(index, 1);
      this.setState({
        fileList: [...fileList],
      });
    }
  };
  fileClick = (event, data) => {
    event.preventDefault();
    const fileList = this.getFileList().map(item => {
      // 此处的 original 为文件名，传入gallery组件时，original 应该为url地址，这里先删除，在viewImage 内部处理
      return _.omit(item, ['original']);
    });
    viewImage({ ...data }, fileList);
  };
  renderItem = (item, index) => {
    const { disabled } = this.props;
    const fileType = getFileType(item.name || item.original);
    const canView = CAN_PREVIEW_FILE_TYPE.includes(fileType);
    return (
      <a
        key={item.uid || index}
        download={item.name || item.original}
        className="file-list-item"
        target="_blank"
        rel="noopener noreferrer"
        title={item.name}
        href={item.url}
        onClick={e => canView && this.fileClick(e, item)}
      >
        {item.name}
        {!disabled && <Icon iconType="icon-error-o" onClick={this.handleRemove(index)} />}
      </a>
    );
  };
  getFileList = () => {
    const fileList = (this.underControl ? this.props.fileList : this.state.fileList) || [];
    return fileList.map(item => ({
      ...item,
      src: item.url,
    }));
  };
  render() {
    const fileList = this.getFileList();
    const { className, showUploadList } = this.props;
    return (
      <div className={`${prefixCls} ${className}`}>
        {!!showUploadList && fileList.map(this.renderItem)}
        <div className="upload-select" onClick={this.onClick}>
          {this.props.children}
        </div>
        <input type="file" style={{ display: 'none' }} ref={r => (this.fileInput = r)} onChange={this.handleSelect} />
      </div>
    );
  }
}
export default Upload;
