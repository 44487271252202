import classnames from 'classnames';
import { Icon } from 'components';
import { CHECK, ERROR, INFO, WARN } from 'constants';
import { fadeIn } from 'constants/animation';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { animateClass } from 'utils';
import { datHelper, getDatTitle } from 'utils/dataAnalytic';
import { prefixCls } from './index.scss';

const commonHideFn = prop => (prop && prop.type ? [INFO, CHECK].includes(prop.type) : true);
export default class PopTip extends PureComponent {
  static defaultProps = {
    type: CHECK,
    isShow: true,
    autoDestroy: true, // 自动销毁
    autoHide: true, // 自动消失 自动销毁为true 则销毁
    autoHideFn: commonHideFn, // 自动消失判断函数，当存在autoHideFn时，忽略autoHide，效果与autoHide一致
    autoHideTime: 2000, // 自动消失时间
  };
  static displayName = 'PopTip';
  constructor(prop) {
    super(prop);
    this.state = {
      isShow: false,
    };
  }
  static propTypes = {
    classname: PropTypes.string,
    type: PropTypes.oneOf([WARN, ERROR, INFO, CHECK]),
    isShow: PropTypes.bool,
    close: PropTypes.func,
    content: PropTypes.any,
    autoDestroy: PropTypes.bool,
    autoHide: PropTypes.bool,
    autoHideTime: PropTypes.number,
    autoHideFn: PropTypes.func,
    // dragerContainer: PropTypes.any
  };

  handleHide = () => {
    // fadeout 动画在popup中执行
    if (this.props.autoDestroy && typeof this.props.close === 'function') {
      // this.state.isShow && animateClass(this.popTip, fadeOut, () => this.props.close())
      this.state.isShow && this.props.close();
    } else {
      // this.state.isShow && animateClass(this.popTip, fadeOut, () => this.setState({ isShow: false }))
      this.state.isShow && this.toggleShow();
    }
  };
  toggleShow = (isShow = !this.state.isShow) => {
    this.setState({ isShow });
  };
  handleShow = () => {
    !this.state.isShow && animateClass(this.popTip, fadeIn, () => this.setState({ isShow: true }));
  };
  componentDidMount = () => {
    const { isShow, autoHide, autoHideTime, autoHideFn } = this.props;
    const localAutoHide = _.isFunction(autoHideFn) ? autoHideFn(this.props, this.state) : autoHide;
    if (isShow) {
      animateClass(this.popTip, fadeIn, () => this.setState({ isShow: true }));
      if (localAutoHide) {
        setTimeout(() => this.handleHide(), autoHideTime);
      }
    }
    this.toggleShow(this.props.isShow);
    datHelper(() => {
      const { content, title: info, type } = this.props;
      if (type !== ERROR) return;
      const title = getDatTitle({ prefixSys: false });
      const name = typeof content === 'string' ? content : info;
      window._paq.push(['trackContentImpression', '打开提示弹窗', `【${title}】-${name}`, content]);
    });
  };

  render() {
    const { type, classname, content } = this.props;
    const classes = classnames({
      [prefixCls]: true,
      [classname]: classname,
    });
    return (
      <div className={`${prefixCls}_wrap`} ref={r => (this.popTip = r)} onClick={this.handleHide}>
        {this.state.isShow && (
          <div className={classes}>
            <div className={`${prefixCls}__${type}`} onClick={e => e.stopPropagation()}>
              <div className={`${prefixCls}__close`} onClick={this.handleHide}>
                <Icon iconType="icon-close" />
              </div>
              <div className={`${prefixCls}__icon`}>
                <Icon iconType={`${type}`} />
              </div>
              <div className={`${prefixCls}__content`}>
                <p>{content}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
