/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-return-assign */
import { CAN_PREVIEW_FILE_TYPE } from '@/constants/fileType.js';
import { getResourceUrl } from '@/utils';
import extraGlobalState from '@/utils/extraGlobalState';
import { getNtoccOssKey } from '@/utils/oss';
import { uploadFileToOss } from '@gui/utils/es/oss-utils';
import { Tooltip } from '@gui/web-react';
import { Icon, PopUp } from 'components';
import { ERROR } from 'constants';
import PropTypes from 'prop-types';
import React from 'react';
import { compressImg, fetchApi, fetchJAVA, genImageURL, getGId, hasPermission, showInfo, viewImage } from 'utils';
import bem from 'utils/bem';
import { v4 as uuidv4 } from 'uuid';
import { withDragFunctions } from '../common/index';
import UploadImgCamera from '../UploadImgCamera';
import { prefixCls } from './index.scss';

const IMAGES = /(gif|jpg|jpeg|png|GIF|JPG|PNG|bmp)$/;
const DOC = /(doc|docx)$/;
const PDF = /pdf$/;
const EXCEL = /(xls|xlsx)$/;
const EML = /eml$/; // 扩展eml文件类型
const AAC = /(aac|AAC)$/;

// 根据文件后缀名，判断是否是某种类型的文件
const isType = (name, type) => {
  try {
    const ext = name.split('.').pop();
    const reg = new RegExp(`${type}$`, 'i');
    return reg.test(ext);
  } catch (error) {
    return false;
  }
};
const getType = name => {
  const type = name.split('.').pop();
  if (IMAGES.test(type)) {
    return 'image';
  }
  if (DOC.test(type)) {
    return 'doc';
  }
  if (PDF.test(type)) {
    return 'pdf';
  }
  if (EXCEL.test(type)) {
    return 'xls';
  }
  if (EML.test(type)) {
    return 'eml';
  }
  if (AAC.test(type)) {
    return 'aac';
  }
  return 'file';
};

const DEFAULT_FILE_TYPE = 'image/*,.pdf,.doc,.docx,.txt,.xls,.xlsx,.eml,.aac';

class UploadFile extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    // 接受上传的文件类型
    // accept: PropTypes.string,
    accept: PropTypes.oneOf(['image', 'file']),
    type: PropTypes.string,
    // 默认已经上传的文件列表
    defaultFileList: PropTypes.array,
    // 是否禁用
    disabled: PropTypes.bool,
    // 已经上传的文件列表（受控）
    fileList: PropTypes.array,
    // 上传自定义请求头
    headers: PropTypes.object,
    // 是否支持多选,ie10+支持。开启后按住 ctrl 可选择多个文件
    multiple: PropTypes.bool,
    // 点击移除文件时的回调，返回false时阻止移除
    onRemove: PropTypes.func,
    // 改变回调。
    onChange: PropTypes.func,
    // 单个文件大小限制
    sizeLimit: PropTypes.number,
    // 文件数量限制
    maxNum: PropTypes.number,
    // 是否显示拍照上传
    showCamera: PropTypes.bool,
    // 显示样式
    showType: PropTypes.oneOf(['link', 'img', 'link-img']),
    tips: PropTypes.string,
    // 拖拽节点
    dragElement: PropTypes.object,
    // 附件是否需要预览
    fileNeedPreview: PropTypes.bool,
    // 是否使用oss上传
    isOss: PropTypes.bool,
    ossFrom: PropTypes.string,
    // 显示文件名
    displayName: PropTypes.oneOf(['link', 'hover']),
    // 超过多少，显示 ...
    ellipsis: PropTypes.number,
    // 文件类型，如有值，则使用，如果为空，则根据 accept 判断
    acceptFileTypes: PropTypes.string,
    // 点击预览的回调，提供给调用方自定义一些逻辑
    onPreviewCallback: PropTypes.func,
  };
  static defaultProps = {
    accept: 'file',
    sizeLimit: 2,
    multiple: true,
    type: 'bill',
    showType: 'img',
    maxNum: 5,
    showCamera: true,
    fileNeedPreview: false,
    isOss: false,
    acceptFileTypes: '',
  };
  constructor(props) {
    super(props);
    this.state = {
      fileList: this.formatter(props.defaultFileList || props.fileList || []),
      showCamera: props.showCamera && hasPermission('upload_photo'),
      uploading: false,
    };
    this.cls = bem(`${prefixCls}-${props.showType === 'img' ? 'img' : 'link'}`);
  }
  componentDidMount() {
    const { dragElement } = this.props;
    this.addDragEvent(dragElement);
  }
  componentWillUnmount() {
    const { dragElement } = this.props;
    this.removeDragEvent(dragElement);
  }
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(np) {
    if (np.fileList !== this.props.fileList) {
      this.setState({
        fileList: this.formatter(np.fileList),
      });
    }
  }
  addDragEvent = element => {
    if (element && element.addEventListener) {
      element.addEventListener('drop', this.handleDrop);
      element.addEventListener('dragover', this.props.handleDragOver);
      element.addEventListener('dragenter', this.props.handleDragEnter);
      element.addEventListener('dragleave', this.props.handleDragLeave);
    }
  };
  removeDragEvent = element => {
    if (element && element.removeEventListener) {
      element.removeEventListener('drop', this.handleDrop);
      element.removeEventListener('dragover', this.props.handleDragOver);
      element.removeEventListener('dragenter', this.props.handleDragEnter);
      element.removeEventListener('dragleave', this.props.handleDragLeave);
    }
  };
  handleDrop = e => {
    this.props.handleDrop(e, this.onSelectFile);
  };
  formatter = fileList =>
    (fileList || []).map?.(item => ({
      ...item,
      file_type: item.file_type || getType(item.name),
    }));

  UploadFile = async (file, file_type) => {
    const { type, sizeLimit } = this.props;
    if (file.size > 1024 * 1024 * sizeLimit) {
      showInfo(ERROR, `文件大小不能超过${sizeLimit}M`);
      return null;
    }

    if (this.props.isOss) {
      try {
        const useNewWayEnabled = extraGlobalState.getState('isNewWayEnabled');
        const res = useNewWayEnabled
          ? await uploadFileToOss({
              file,
              request: fetchConfig => {
                const { params } = fetchConfig;
                return fetchJAVA('/cmm-basic/resource/getAuthKey', {
                  method: 'POST',
                  ...params,
                });
              },
              requestConfig: {
                params: {
                  body: {
                    req: {
                      type: this.props.type,
                      group_id: window?.group_id,
                    },
                  },
                },
              },
              formateReceiveData: resData => {
                const { path, uploadData } = resData;
                return {
                  name: uploadData.name,
                  path,
                  showName: uploadData.name,
                };
              },
            })
          : await this.uploadToOss(file, 'file');
        return {
          ...res,
          type,
          file_type,
        };
      } catch (error) {
        console.log('error', error);
        return null;
      }
    }

    const formData = new FormData();
    const req = { type, name: file.name };
    formData.append('req', JSON.stringify(req));
    formData.append('upfile', file);

    return fetchApi('Basic/File/uploadFile', {
      method: 'POST',
      body: formData,
    })
      .then(res => {
        if (res.errno === 0) {
          const { original, title } = res.res;
          return {
            name: file.name,
            type,
            path: title,
            file_type,
            showName: file.name,
          };
        }
        showInfo(ERROR, res.errmsg || '上传失败，请稍后重试或联系售后支持！');
        return null;
      })
      .catch(() => {
        showInfo(ERROR, '上传失败，请稍后重试或联系售后支持！');
        return null;
      });
  };

  xhrPromise = ({ method, url, data, file, fileName, onProgress }) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(method, url, true);

      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            resolve();
          } catch (e) {
            reject(new Error('Invalid JSON response'));
          }
        } else {
          reject(new Error(`Request failed with status ${xhr.status}`));
        }
      };

      xhr.onerror = function () {
        reject(new Error('Network error'));
      };

      if (onProgress && xhr.upload) {
        xhr.upload.onprogress = onProgress;
      }

      const formData = new FormData();
      Object.keys(data).forEach(key => formData.append(key, data[key]));
      formData.append('file', file, fileName);

      xhr.send(formData);
    });
  };

  uploadToOss = async (file, fileType) => {
    try {
      const ossConfig = await getNtoccOssKey(true, this.props.type);
      if (!ossConfig) {
        throw new Error('获取OSS配置失败');
      }

      const fileName = file.name;
      const time = Date.now() + uuidv4().slice(0, 8); // 加一个随机数，防止重复
      const fileExtension = fileName?.split('.').pop();
      const isFile = fileType === 'file';
      const dirStr = ossConfig.dir ?? '';
      const dirPathArray = dirStr.split('/');
      const ossKey = `${dirStr}${time}.${fileExtension}`;
      // 判断后缀是否含有 ‘/’ 需删除
      if (dirStr.endsWith('/')) {
        dirPathArray.pop();
      }
      const noExtensionPath = dirPathArray.join('/');

      const options = {
        method: 'POST',
        url: ossConfig.host,
        data: {
          key: ossKey,
          policy: ossConfig.policy,
          OSSAccessKeyId: ossConfig.accessId,
          signature: ossConfig.signature,
          success_action_status: '200',
          name: fileName,
        },
        file,
        fileName,
        onProgress: this.handleProgress,
      };
      await this.xhrPromise(options);
      // 后端对图片地址做了拼接
      const backParams = {
        name: isFile ? fileName : `${time}.${fileExtension}`,
        path: isFile ? ossKey : noExtensionPath,
        showName: fileName,
      };
      // 做保留，后端标识
      // if (this.props?.ossFrom) {
      //   backParams.ossFrom = this.props.ossFrom;
      // }
      return {
        ...backParams,
      };
    } catch (error) {
      showInfo(ERROR, '上传失败，请稍后重试或联系售后支持！');
      throw error;
    }
  };
  // 预留函数，展示进度
  handleProgress = event => {
    if (event.total > 0) {
      const percent = (event.loaded / event.total) * 100;
      console.log(percent);
    }
  };

  uploadImage = async (file, zip = true) => {
    let img = { ...file };
    const { type, sizeLimit } = this.props;
    if (zip) {
      const realSize = file.size / 1024 / 1024;
      const quality = realSize > sizeLimit ? (sizeLimit / realSize) * 0.2 + 0.6 : 0.8;
      // 超过最大尺寸
      if (realSize > sizeLimit) {
        // 判断是否为gif，gif无法压缩
        if (isType(file?.name ?? '', 'gif')) {
          showInfo(ERROR, `图片大小不能超过${sizeLimit}M`);
          return null;
        }
        // 其他图片走压缩，最后都会转成jpeg
        img = await compressImg(file, { quality });
        if (img.file.size > 1024 * 1024 * sizeLimit) {
          showInfo(ERROR, `图片压缩后大小不能超过${sizeLimit}M`);
          return null;
        }
      }
    }

    if (this.props.isOss) {
      try {
        const useNewWayEnabled = extraGlobalState.getState('isNewWayEnabled');
        const res = useNewWayEnabled
          ? await uploadFileToOss({
              file,
              request: fetchConfig => {
                const { params } = fetchConfig;
                return fetchJAVA('/cmm-basic/resource/getAuthKey', {
                  method: 'POST',
                  ...params,
                });
              },
              requestConfig: {
                params: {
                  body: {
                    req: {
                      type: this.props.type,
                      group_id: window?.group_id,
                    },
                  },
                },
              },
              formateReceiveData: resData => {
                const { path, uploadData } = resData;
                const imageName = path.split('/').pop();
                return {
                  name: imageName,
                  path,
                  showName: uploadData.name,
                };
              },
            })
          : await this.uploadToOss(file, 'image');
        return {
          ...res,
          type,
          file_type: 'image',
        };
      } catch (error) {
        return null;
      }
    }

    const formData = new FormData();
    const req = { type, name: file.name, ...this.props.asynReq };
    formData.append('req', JSON.stringify(req));
    formData.append('img[]', img.file ?? file);

    return fetchApi('/Basic/Image/uploadImages', {
      method: 'POST',
      body: formData,
    })
      .then(res => {
        if (res.errno === 0) {
          const { name, path, ocr_data } = res.res.image_info.img[0];
          return {
            name,
            type,
            path,
            file_type: 'image',
            ocr_data,
            showName: file.name,
          };
        }
        return null;
      })
      .catch(() => {
        showInfo(ERROR, '上传失败，请稍后重试或联系售后支持！');
        return null;
      });
  };
  onChange = files => {
    const fileList = files.filter(item => item);
    this.setState({
      fileList,
    });
    this.props.onChange && this.props.onChange(fileList);
  };
  onRemove = (index, e) => {
    e && e.stopPropagation();
    if (this.props.disabled) {
      return;
    }
    const { fileList } = this.state;
    fileList.splice(index, 1);
    if (this.props.onRemove) {
      this.props.onRemove(index);
    }
    if (this.fileInput) {
      this.fileInput.value = '';
    }
    this.onChange([...fileList]);
  };
  onSelectFile = (e, isByDrag) => {
    const files = isByDrag ? [...e] : [...e.target.files];
    const { maxNum } = this.props;
    const { fileList } = this.state;

    if (fileList.length + files.length > maxNum) {
      return showInfo(ERROR, `最多上传${maxNum}个文件`);
    }

    this.setState({ uploading: true });

    const uploads = files.map(async file => {
      const type = getType(file.name);
      if (type === 'file') {
        showInfo(ERROR, '上传格式有误，请重新上传！');
        return null;
      }
      return type === 'image' ? await this.uploadImage(file) : await this.UploadFile(file, type);
    });

    Promise.all(uploads)
      .then(res => {
        const validUploads = res.filter(item => item);
        if (validUploads.length > 0) {
          this.onChange([...this.state.fileList, ...validUploads]);
        }
      })
      .finally(() => {
        this.setState({ uploading: false });
      });
  };
  handleCamera = rst => {
    const { maxNum } = this.props;
    const { fileList } = this.state;
    if (fileList.length + 1 > maxNum) {
      return showInfo(ERROR, `最多上传${maxNum}个文件`);
    }
    this.uploadImage({ name: rst.name, file: rst.formdata }, false).then(img => {
      if (img) {
        this.onChange([...this.state.fileList, img]);
      }
    });
  };
  onCamera = () => {
    new PopUp(UploadImgCamera, {
      upLoadCb: this.handleCamera,
      isModal: true,
    }).show();
  };
  onSelect = () => {
    this.fileInput.click();
  };
  getFileParam = file => ({
    gid: getGId(),
    type: file.type,
    filename: file.path,
    showname: encodeURIComponent(file.name),
  });
  getImgUrlWidthOss = file => {
    return genImageURL(file);
  };
  onDownload = index => {
    const { fileList } = this.state;
    const file = fileList[index];
    const { type, fileNeedPreview } = this.props;
    // 是否是预览，只有设置了附件需要预览参数且是pdf才预览
    const isPdfPreview = fileNeedPreview && file.file_type === 'pdf';
    const param = this.getFileParam(file);
    param.needPreview = Number(isPdfPreview);
    const aLink = document.createElement('a');
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('click', true, true); // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为

    // 只有设置了附件需要预览参数且是pdf才预览
    if (isPdfPreview) {
      aLink.target = '_blank';
    } else {
      aLink.target = '_self';
      aLink.download = param.showname;
    }

    const originUrl = `/api/Basic/File/getFile?req=${JSON.stringify(param)}`;
    const src = getResourceUrl({
      originUrl,
      fileContent: file,
    });
    aLink.href = src;
    aLink.click();
  };
  onReview = index => {
    const { fileList } = this.state;
    const file = fileList[index];
    viewImage(file, fileList);
    this.props.onPreviewCallback?.();
  };
  onClick = index => {
    const { fileList, showCamera } = this.state;
    const item = fileList[index];
    CAN_PREVIEW_FILE_TYPE.includes(item.file_type) ? this.onReview(index) : this.onDownload(index);
  };
  renderLink = (item, index) => {
    const { showType, disabled, ellipsis, displayName } = this.props;
    const showEllipsis = ellipsis && index + 1 > ellipsis;
    const displayText = displayName === 'link' ? item.showName ?? item.name : index + 1;
    const linkEl = (
      <span className={this.cls('item-text')} style={{ whiteSpace: 'nowrap' }}>
        [{showEllipsis ? '...' : `${displayText}`}]
      </span>
    );

    const onPreviewClick = (e, idx) => {
      e?.stopPropagation();
      e?.preventDefault();
      this.onClick(idx + ellipsis);
    };

    // ... 上显示所有的文件名
    const tipsContent = showEllipsis
      ? this.state.fileList?.slice(ellipsis).map((itam, idx) => (
          <div onClick={e => onPreviewClick(e, idx)} style={{ cursor: 'pointer' }}>
            {itam.showName ?? itam.name}
          </div>
        ))
      : item.showName ?? item.name;
    const linkWrap = displayName === 'hover' ? <Tooltip content={tipsContent}>{linkEl}</Tooltip> : linkEl;
    return (
      <span className={this.cls('item', 'link')} onClick={() => this.onClick(index)}>
        {showType === 'link' ? linkWrap : <img src={this.getImgUrlWidthOss(item)} width="16px" height="16px" />}
        {!disabled ? (
          <Icon classname={this.cls('item-delete')} onClick={e => this.onRemove(index, e)} iconType="icon-error-o" />
        ) : null}
      </span>
    );
  };
  renderLinkType() {
    const { multiple, accept, maxNum, className, disabled, showType, tips, ellipsis, acceptFileTypes } = this.props;
    const { fileList = [], showCamera } = this.state;
    const localFileList = [...fileList];
    const showUpload = !disabled && fileList.length < maxNum;
    const acceptTypes = acceptFileTypes || (accept === 'image' ? 'image/*' : DEFAULT_FILE_TYPE);
    // 或者超过指定的ellipsis数量，显示...
    if (ellipsis && fileList.length > ellipsis) {
      localFileList.length = ellipsis + 1;
    }
    return (
      <div className={`${this.cls('')} ${className}`}>
        {showUpload && (
          <Icon iconType="icon-upload1" classname={this.cls('item', 'upload')} onClick={this.onSelect} tips={tips} />
        )}
        {
          // showUpload && showCamera && <Icon
          //   iconType="icon-photo"
          //   classname={this.cls('item', 'camera')}
          //   onClick={this.onCamera}
          // />
        }
        {localFileList.map(this.renderLink)}
        <input
          className={this.cls('input')}
          type="file"
          accept={acceptTypes}
          multiple={multiple && maxNum > 1}
          ref={r => (this.fileInput = r)}
          onChange={this.onSelectFile}
        />
      </div>
    );
  }
  renderImg = (item, index) => {
    const { displayName } = this.props;
    const fileName = displayName === 'link' ? item.showName ?? item.name : index + 1;

    return (
      <div className={this.cls('item', { [item.file_type]: true })} title={item.name}>
        {item.file_type === 'image' ? (
          <img className={this.cls('item-img')} src={this.getImgUrlWidthOss(item)} />
        ) : (
          <div className={this.cls('item-icon', { [item.file_type]: true })} />
        )}
        {item.file_type !== 'image' && displayName !== 'link' && (
          <span className={this.cls('tip')}>{item.file_type}</span>
        )}
        {item.file_type !== 'image' && displayName === 'link' && (
          <span className={this.cls('file-name')}>{fileName}</span>
        )}
        <div className={this.cls('item-mask')}>
          {item.file_type === 'image' ? (
            <Icon classname={this.cls('item-view')} iconType="icon-ps-show" onClick={() => this.onReview(index)} />
          ) : (
            <Icon
              classname={this.cls('item-download')}
              iconType={this.props.fileNeedPreview && item.file_type === 'pdf' ? 'icon-ps-show' : 'icon-download'}
              onClick={() => this.onDownload(index)}
            />
          )}
          {!this.props.disabled && (
            <Icon classname={this.cls('item-del')} iconType="icon-del" onClick={() => this.onRemove(index)} />
          )}
        </div>
      </div>
    );
  };

  renderLoading() {
    if (this.state.uploading) {
      return (
        <div className={this.cls('loading')}>
          <span className={this.cls('spin')}>
            <Icon iconType="icon-loading1" />
          </span>
        </div>
      );
    }
  }
  renderImgType() {
    const { multiple, accept, maxNum, className, disabled, showType, acceptFileTypes, children } = this.props;
    const { fileList, showCamera } = this.state;
    const showUpload = !disabled && fileList.length < maxNum;
    const acceptTypes = acceptFileTypes || (accept === 'image' ? 'image/*' : DEFAULT_FILE_TYPE);
    return (
      <div className={`${this.cls('')} ${className}`}>
        {showUpload && (
          <div
            className={this.cls('item', 'upload')}
            onClick={this.onSelect}
            onDragEnter={this.props.handleDragEnter}
            onDragLeave={this.props.handleDragLeave}
            onDrop={e => {
              this.props.handleDrop(e, this.onSelectFile);
            }}
            onDragOver={this.props.handleDragOver}
            style={{
              minWidth: '160px',
            }}
          >
            {children || (
              <>
                <Icon iconType="icon-upload1" />
                <span className={this.cls('label')}>点击{this.props?.drag ? '/拖拽上传' : '上传'}</span>
                <span className={this.cls('tip')}>
                  (最多{maxNum}
                  {accept === 'image' ? '张' : '个'})
                </span>
              </>
            )}
            {this.renderLoading()}
          </div>
        )}
        {
          // showUpload && showCamera && (<div className={this.cls('item', 'camera')} onClick={this.onCamera}>
          //   <Icon iconType="icon-photo" />
          //   <span className={this.cls('label')}>拍照上传</span>
          //   <span className={this.cls('tip')}>(最多{maxNum}张)</span>
          // </div>)
        }
        {fileList.map(this.renderImg)}
        <input
          className={this.cls('input')}
          type="file"
          accept={acceptTypes}
          multiple={multiple && maxNum > 1}
          ref={r => (this.fileInput = r)}
          onChange={this.onSelectFile}
        />
      </div>
    );
  }
  render() {
    return this.props.showType === 'img' ? this.renderImgType() : this.renderLinkType();
  }
}
export default withDragFunctions(UploadFile);
