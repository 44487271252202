import PropTypes from 'prop-types';
import { Component } from 'react';
import { isEmptyObj } from 'utils';
import fetchApi from 'utils/fetchApi';
import LoadMap from './LoadMap';
import Service from './Service';
import { regeoWebService } from 'utils/aMapWebService';

const services = [
  {
    name: 'PlaceSearch',
    cfg: {
      extensions: 'all',
      city: '全国',
      citylimit: true,
      // type: '190102|190103|190104|190105|190106|190107',
      type:
        '汽车服务|汽车销售|汽车维修|摩托车服务|餐饮服务|购物服务|生活服务|体育休闲服务|' +
        '医疗保健服务|住宿服务|风景名胜|商务住宅|政府机构及社会团体|科教文化服务|' +
        '交通设施服务|金融保险服务|公司企业|道路附属设施|地名地址信息|公共设施',
    },
  },
  {
    name: 'Geocoder',
    cfg: {
      extensions: 'base',
      city: '全国',
    },
  },
  {
    name: 'DistrictSearch',
    cfg: {
      level: 'country',
      extensions: 'base',
      showbiz: true,
      subdistrict: 1,
    },
  },
];
const level2Type = {
  country: '190101', // 国家
  province: '190102', // 省份（直辖市会在province和city显示）
  city: '190103', // '190103,190104', // 直辖市级地名, 地级市（直辖市会在province和city显示）
  district: '190105', // 区县
  street: '190106', // ,190107', // 街道
};
const type2Level = {
  '地名地址信息;普通地名;国家名': 'country', // 国家
  '地名地址信息;普通地名;省级地名': 'province', // 省份（直辖市会在province和city显示）
  '地名地址信息;普通地名;直辖市级地名': 'city', // 直辖市级地名, 地级市（直辖市会在province和city显示）
  '地名地址信息;普通地名;地市级地名': 'city', // 直辖市级地名, 地级市（直辖市会在province和city显示）
  '地名地址信息;普通地名;区县级地名': 'district', // 区县
  '地名地址信息;普通地名;乡镇级地名': 'street', // 街道
  '地名地址信息;普通地名;街道级地名': 'street', // 街道
};
// const typeCode2Type = {
//   0: '190101', // 国家
//   1: '190102', // 省份（直辖市会在province和city显示）
//   2: '190103,190104', // 直辖市级地名, 地级市（直辖市会在province和city显示）
//   3: '190105', // 区县
//   4: '190106,190107', // 街道
// }
@LoadMap
@Service(services)
class AddrSug extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      result: '',
    };
    this.preSearchStr = '';
    this.isInit = true;
    this.xzqh = window.ext_info.tmpAddrConfig; // 是否使用自有接口
    this.xzqhUrl = '/Basic/Amap/xzqh';
  }

  static defaultProps = {
    searchStr: '',
    manualSearch: false,
    mode: 'address',
    resultNum: 20,
  };
  static contextTypes = {
    serviceCb: PropTypes.func,
  };
  static propTypes = {
    service: PropTypes.object,
    searchStr: PropTypes.string,
    manualSearch: PropTypes.bool,
    onResult: PropTypes.func,
    mode: PropTypes.string, // 查询模式  district , address
    resultNum: PropTypes.number, // 结果条数 最大支持50，
    fetch: PropTypes.func,
  };
  setServiceType = type => {
    const addrServices = this.state.ist;
    if (!addrServices || isEmptyObj(addrServices) || !addrServices.PlaceSearch) return false;
    addrServices.PlaceSearch.setType(type);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { searchStr, manualSearch, mode } = nextProps;
    const addrServices = this.state.ist;
    if (
      addrServices &&
      addrServices.PlaceSearch &&
      (searchStr.trim() !== '' || this.props.fetch) &&
      (searchStr !== this.preSearchStr || manualSearch)
    ) {
      this.preSearchStr = searchStr;
      if (this.isInit || mode !== this.props.mode) {
        // this.isInit = false
        // const type = mode === 'district' ? '地名地址信息' : ''
        // 190000|190100|190101|190102|190103|190104|190105
        // 乡镇、街道 。。。|190106|190107
        // 村、组 |190108|190109|190200
        const modeType = {
          address: '',
          district: '190101|190102|190103|190104|190105|190106|190107', // '190101|190102|190103|190104|190105',
          street: '190106|190107',
          province: '190102',
          city: '190104',
          county: '190105',
        };
        const type = modeType[mode] || '';
        addrServices.PlaceSearch.setCityLimit(false);
        addrServices.PlaceSearch.setType(type);
        addrServices.PlaceSearch.setPageSize(this.props.resultNum);
      }
      // 行政区划模式
      if (mode === 'district') {
        if (this.xzqh) {
          this.amapXzqh(searchStr, (status, result) => {
            // console.log(status, result, arg)
            if (status === 'no_data') {
              this.props.onResult(status, {
                poiList: {
                  pois: [],
                },
              });
            } else if (status === 'error') {
              this.props.onResult(status, {
                info: result,
                poiList: {
                  pois: [],
                },
              });
            } else if (result.pois.length === 0) {
              this.props.onResult(status, {
                poiList: {
                  pois: [],
                },
              });
            } else {
              this.props.onResult(status, {
                poiList: {
                  pois: result.pois,
                },
              });
            }
          });
        } else {
          addrServices.DistrictSearch.search(searchStr, (status, result) => {
            if (status === 'no_data') {
              this.props.onResult(status, {
                poiList: {
                  pois: [],
                },
              });
            } else if (status === 'error') {
              this.props.onResult(status, {
                info: result,
                poiList: {
                  pois: [],
                },
              });
            } else if (result.districtList.length === 0) {
              this.props.onResult(status, {
                poiList: {
                  pois: [],
                },
              });
            } else {
              let childrenList = [];
              let locations = result.districtList.map(item => {
                item.districtList && (childrenList = childrenList.concat(item.districtList));
                return item;
              });
              locations = locations.concat(childrenList);
              // addrServices.Geocoder.getAddress(
              //   locations.map(item => item.center),
              //   (s, r) => {
              //     result.poiList = {
              //       // eslint-disable-line
              //       pois: r.regeocodes.map((geo, i) => {
              //         const d = geo.addressComponent;
              //         const _L = locations[i].level;
              //         const showpname = `${_L !== 'province' ? d.province : '中国'}${
              //           _L === 'district' || _L === 'street' ? d.city : ''
              //         }${_L === 'street' ? d.district : ''}`;
              //         return {
              //           name: locations[i].name,
              //           showpname,
              //           pcode: d.pcode,
              //           pname: d.province,
              //           citycode: _L === 'province' ? '' : d.citycode,
              //           cityname: _L === 'province' ? '' : d.city,
              //           adcode: d.adcode || '',
              //           adname: _L === 'province' || _L === 'city' ? '' : d.district,
              //           street: _L === 'province' || _L === 'city' || _L === 'district' ? '' : d.township || '', // 街道
              //           address: geo.formattedAddress,
              //           type: '', // 地址类型
              //           typecode: level2Type[d.level], // 地址类型码
              //           location: locations[i].center,
              //         };
              //       }),
              //     };
              //     this.props.onResult(status, result);
              //   },
              // );

              regeoWebService(locations.map(item => item.center)).then(r => {
                result.poiList = {
                  // eslint-disable-line
                  pois: r.regeocodes.map((geo, i) => {
                    const d = geo.addressComponent;
                    const _L = locations[i].level;
                    const showpname = `${_L !== 'province' ? d.province : '中国'}${
                      _L === 'district' || _L === 'street' ? d.city : ''
                    }${_L === 'street' ? d.district : ''}`;
                    return {
                      name: locations[i].name,
                      showpname,
                      pcode: d.pcode,
                      pname: d.province,
                      citycode: _L === 'province' ? '' : d.citycode,
                      cityname: _L === 'province' ? '' : d.city,
                      adcode: d.adcode || '',
                      adname: _L === 'province' || _L === 'city' ? '' : d.district,
                      street: _L === 'province' || _L === 'city' || _L === 'district' ? '' : d.township || '', // 街道
                      address: geo.formattedAddress,
                      type: '', // 地址类型
                      typecode: level2Type[d.level], // 地址类型码
                      location: locations[i].center,
                    };
                  }),
                };
                this.props.onResult(status, result);
              });
            }
          });
        }
      } else {
        // 地址查询模式
        addrServices.PlaceSearch.search(searchStr, (status, result) => {
          if (status === 'no_data') {
            this.props.onResult(status, {
              poiList: {
                pois: [],
              },
            });
          } else if (status === 'error') {
            this.props.onResult(status, {
              info: result,
              poiList: {
                pois: [],
              },
            });
          } else if (result.poiList.pois.length === 0) {
            this.props.onResult(status, {
              poiList: {
                pois: [],
              },
            });
          } else {
            const locations = result.poiList.pois.map(item => item.location);

            // addrServices.Geocoder.getAddress(locations, (s, r) => {
            //   r.regeocodes.forEach((geo, i) => {
            //     const d = geo.addressComponent;
            //     const _type = result.poiList.pois[i].type;
            //     const _L = type2Level[_type];
            //     const typecode = level2Type[_L];
            //     const showpname = `${_L !== 'province' ? d.province : '中国'}${
            //       _L === 'district' || _L === 'street' ? d.city : ''
            //     }${_L === 'street' ? d.district : ''}`;
            //     const _poi = {
            //       level: _L,
            //       typecode,
            //       showpname,
            //       citycode: _L === 'province' ? '' : d.citycode,
            //       cityname: _L === 'province' ? '' : d.city,
            //       adcode: d.adcode || '',
            //       adname: _L === 'province' || _L === 'city' ? '' : d.district,
            //       street: _L === 'province' || _L === 'city' || _L === 'district' ? '' : d.township || '',
            //     };
            //     result.poiList.pois[i] = { ...result.poiList.pois[i], ..._poi }; // eslint-disable-line
            //   });
            //   this.props.onResult(status, result);
            // });

            regeoWebService(locations).then(r => {
              r.regeocodes.forEach((geo, i) => {
                const d = geo.addressComponent;
                const _type = result.poiList.pois[i].type;
                const _L = type2Level[_type];
                const typecode = level2Type[_L];
                const showpname = `${_L !== 'province' ? d.province : '中国'}${
                  _L === 'district' || _L === 'street' ? d.city : ''
                }${_L === 'street' ? d.district : ''}`;
                const _poi = {
                  level: _L,
                  typecode,
                  showpname,
                  citycode: _L === 'province' ? '' : d.citycode,
                  cityname: _L === 'province' ? '' : d.city,
                  adcode: d.adcode || '',
                  adname: _L === 'province' || _L === 'city' ? '' : d.district,
                  street: _L === 'province' || _L === 'city' || _L === 'district' ? '' : d.township || '',
                };
                result.poiList.pois[i] = { ...result.poiList.pois[i], ..._poi }; // eslint-disable-line
              });
              this.props.onResult(status, result);
            });
          }
        });
      }
    }
  }

  // /api/Basic/Amap/xzqh?req={"search":"南京","slice_num":"10"}
  amapXzqh = (search, cb) => {
    const fetch = this.props.fetch
      ? this.props.fetch(search)
      : fetchApi(this.xzqhUrl, {
          method: 'POST',
          body: {
            req: {
              search,
              slice_num: this.props.resultNum,
            },
          },
        });
    fetch
      .then(res => {
        if (res.errno === 0) {
          cb(res.res.pois.length ? 'complete' : 'no_data', res.res);
        } else {
          cb('error', []);
        }
      })
      .catch(err => {
        console.log(err);
        cb('error', []);
      });
  };

  componentDidMount() {
    const inits = Object.assign({}, { winInit: window.init });
    if (window.AMap) {
      // 初始化服务
      this.context.serviceCb.call(this, null);
    } else {
      window.init = () => {
        inits.winInit && inits.winInit();
        // 初始化amap
        if (!window.AMap) {
          console.error('AMap is required');
        } else {
          // 初始化服务
          this.context.serviceCb.call(this, null);
        }
      };
    }
  }

  render() {
    return null;
  }
}
export default AddrSug;
